<template>
	<v-card height="360">
		<v-card-title>
			<v-icon color="primary">mdi-pin-outline</v-icon>
			<span class="primary--text pl-2">공지</span>
		</v-card-title>
		<template v-if="notices.length > 0">
			<div
				v-for="(item, index) in notices"
				:key="index"
				@click="openMyPageNotificationDialog(item)"
				class="cursor-pointer notice-box border-bottom px-2 py-4"
				:class="isMdAndUp ? 'mx-4' : 'mx-2'"
			>
				<div class="d-flex justify-space-between align-center">
					<div>
						<strong class="notice-title">
							{{ item.title }}
						</strong>
						<span class="text-xs d-block secondary--text">
							{{ item.createdAt | dateFormat }}
						</span>
					</div>
					<div>
						<v-icon>mdi-chevron-right</v-icon>
					</div>
				</div>
			</div>
		</template>
		<template v-else>
			<div>
				<div class="d-flex justify-center align-center">-</div>
			</div>
		</template>
		<my-page-notice-dialog
			:selected-notice="selectedNotice"
			:is-open-my-page-notice-dialog.sync="isOpenMyPageNoticeDialog"
		/>
	</v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import NoticeService from '@/services/NoticeService'

import MyPageNoticeDialog from '../../MyPageNotification/MyPageNoticeDialog.vue'

export default {
	components: {
		MyPageNoticeDialog,
	},
	setup() {
		const $vuetify = getVuetify()
		const selectedNotice = ref({})
		const isOpenMyPageNoticeDialog = ref(false)
		const notices = ref([])

		const getNotices = async () => {
			let data = await NoticeService.getNotices()
			data = data.filter(obj => obj.visible === true)
			notices.value = data.slice(0, 4)
		}
		getNotices()

		const openMyPageNotificationDialog = item => {
			selectedNotice.value = item
			isOpenMyPageNoticeDialog.value = !isOpenMyPageNoticeDialog.value
		}

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		return {
			notices,
			isMdAndUp,
			selectedNotice,
			isOpenMyPageNoticeDialog,

			openMyPageNotificationDialog,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.notice-box {
	border-radius: 2px;

	&:hover {
		background-color: #f2f2f2;
	}

	&.border-bottom:not(:last-child) {
		border-bottom: 1px solid #e6e6e6;
	}

	.notice-title {
		display: block;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		width: 280px;
	}
}
</style>
