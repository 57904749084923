<template>
	<div>
		<v-row>
			<v-col cols="12" md="4" sm="12">
				<!-- 프로필 -->
				<my-page-dashboard-profile />
			</v-col>
			<v-col cols="12" md="8" sm="12" :class="isMdAndUp ? 'pl-0' : 'pt-0'">
				<!-- 신청 현황 -->
				<my-page-dashboard-application-board />
			</v-col>
			<v-col cols="12" md="4" sm="12" class="pt-0">
				<!-- 공지 -->
				<my-page-dashboard-notice />
			</v-col>
			<v-col
				cols="12"
				md="8"
				sm="12"
				class="pt-0"
				:class="{ 'pl-0': isMdAndUp }"
			>
				<!-- 일정 -->
				<my-page-dashboard-calendar />
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="4" sm="12" class="pt-0">
				<!-- 팀보드 -->
				<my-page-dashboard-member :key="componentKey" />
			</v-col>
			<v-col
				cols="12"
				md="8"
				sm="12"
				class="pt-0"
				:class="{ 'pl-0': isMdAndUp }"
			>
				<!-- 마이데이터 -->
				<my-page-dashboard-main :key="componentKey" />
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { onMounted, computed, ref } from '@vue/composition-api'

import { getVuetify } from '@core/utils'

import MyPageDashboardMain from './components/MyPageDashboardMain.vue'
import MyPageDashboardNotice from './components/MyPageDashboardNotice.vue'
import MyPageDashboardMember from '../components/MyPageDashboardMember.vue'
import MyPageDashboardProfile from '../components/MyPageDashboardProfile.vue'
import MyPageDashboardCalendar from './components/MyPageDashboardCalendar.vue'
import MyPageDashboardApplicationBoard from './components/MyPageDashboardApplicationBoard.vue'

export default {
	components: {
		MyPageDashboardMain,
		MyPageDashboardNotice,
		MyPageDashboardMember,
		MyPageDashboardProfile,
		MyPageDashboardCalendar,
		MyPageDashboardApplicationBoard,
	},
	setup() {
		const $vuetify = getVuetify()
		const title = ref('고객용 대시보드')
		const dashboardData = ref(null)
		const componentKey = ref(0)

		const forceRerender = () => {
			componentKey.value += 1
		}

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		onMounted(() => {
			forceRerender()
		})

		return {
			title,
			isMdAndUp,
			dashboardData,
			componentKey,
		}
	},
}
</script>
