<template>
	<v-card height="360">
		<v-card-title class="py-5">
			<div>
				<v-icon color="primary">mdi-calendar-check-outline</v-icon>
				<span class="primary--text pl-2">일정</span>
			</div>
		</v-card-title>
		<template v-if="events.length > 0">
			<v-card
				class="calendar-box d-flex px-3 py-3 mb-1 align-center justify-space-between"
				:class="isMdAndUp ? 'mx-5' : 'mx-2'"
				v-for="(item, index) in events"
				:key="index"
			>
				<div class="calendar-content">
					<div class="date" :class="isMdAndUp ? 'text-base' : 'text-sm'">
						<strong v-if="item.eventAt">{{ item.eventAt | dateFormat }}</strong>
						<strong v-else>상시</strong>
					</div>

					<div class="d-flex flex-column calendar-title">
						<strong class="title-text">{{ item.title }}</strong>
						<div class="text-sm" style="position: relative; left: -3px">
							<v-icon small color="info">mdi-map-marker</v-icon>

							<span class="secondary--text location-text">
								{{ item.location }}
							</span>
						</div>
					</div>
				</div>
				<div>
					<v-icon>mdi-chevron-right</v-icon>
				</div>
			</v-card>
		</template>
		<template v-else>
			<div class="d-flex justify-center align-center">일정이 없습니다</div>
		</template>
	</v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import { eventCategory } from '@/helpers'
import { useRouter, getVuetify } from '@core/utils'

import EventService from '@/services/EventService'

export default {
	setup() {
		const $vuetify = getVuetify()
		const { router } = useRouter()
		const events = ref([])

		const getEvents = async () => {
			try {
				const data = await EventService.getEvents(4)
				events.value = data
			} catch (e) {
				console.error(e)
			}
		}
		getEvents()

		const routeToEvent = event => {
			let routerName = 'mypage-application-event'
			if (event.category === eventCategory.LICENSE_TEST.value) {
				routerName = 'mypage-application-event-test'
			}

			router.push({
				name: routerName,
				params: {
					eventId: event.id,
				},
			})
		}

		const isMdAndUp = computed(() => {
			return $vuetify.breakpoint.mdAndUp
		})

		return {
			events,
			isMdAndUp,

			routeToEvent,
		}
	},
}
</script>
<style lang="scss" scoped>
::v-deep {
	.v-select {
		max-width: 80px;
	}

	.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded)
		> .v-input__control
		> .v-input__slot {
		padding: 0 10px;
	}

	.v-text-field--box .v-input__slot,
	.v-text-field--outline .v-input__slot {
		min-height: 36px;
	}
}
.v-card {
	display: block;
	overflow-x: auto;
	white-space: nowrap;
}

.calendar-box {
	&:hover {
		background-color: #f2f2f2;
	}

	.calendar-content {
		display: flex;
		align-items: center;

		@media (max-width: 600px) {
			display: block;
		}
	}

	.calendar-title {
		margin-left: 16px;
		padding-left: 16px;
		border-left: 1px solid #e6e6e6;

		@media (max-width: 600px) {
			margin-left: 0px;
			padding-left: 0px;
			border-left: none;

			.title-text,
			.location-text {
				width: 235px;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	@media (max-width: 600px) {
		.date {
			display: block;
		}
	}
}
</style>
