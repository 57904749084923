/* eslint-disable no-async-promise-executor */
import axios from '@/api/axios'

const eventService = {
	getEvent(eventId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-events/${eventId}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getEvents(limit) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-events?limit=${limit}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeEvent(id) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-events/me/detail?id=${id}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeEvents(category) {
		let categoryField = ''
		if (category) {
			categoryField = `?category=${category}`
		}

		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-events/me/detail${categoryField}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getMeMarketEventParticipations(category) {
		return new Promise(async (resolve, reject) => {
			try {
				let categoryField = ''
				if (category) {
					categoryField = `?category=${category}`
				}

				const { data } = await axios({
					method: 'GET',
					url: `market-event-participations/detail${categoryField}`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	getEventParticipations(eventId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'GET',
					url: `/market-events/${eventId}/participation/detail`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	applyEvent(eventId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-events/${eventId}/participation`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	cancelEvent(eventId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-events/${eventId}/participation/actions/cancel`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	createPaymentEvent(payload) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-events/${payload.id}/participation/actions/payment`,
					data: {
						pgResponse: payload.pgResponse,
					},
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postCheckIn(eventId, userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-events/${eventId}/participation/users/${userId}/actions/check-in`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
	postCheckOut(eventId, userId) {
		return new Promise(async (resolve, reject) => {
			try {
				const { data } = await axios({
					method: 'POST',
					url: `/market-events/${eventId}/participation/users/${userId}/actions/check-out`,
				})
				resolve(data)
			} catch (e) {
				reject(e)
				throw e
			}
		})
	},
}

export default eventService
